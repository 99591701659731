import React from "react"

import Card from "@mui/joy/Card";
import CardMedia from "@mui/material/CardMedia";
import CardContent from "@mui/material/CardContent";
import YouTube from 'react-youtube';
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import "./search.css";
import TextField from "@mui/material/TextField";
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';
import { Checkbox, FormControl, FormControlLabel, FormGroup, FormLabel, Radio, RadioGroup } from "@mui/material";
import parse from "html-react-parser";
import { Link } from "react-router-dom";

class Search extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            error: null,
            isLoaded: false,
            open: false,
            items: [],
            page: 0,
            statistics: {},
            matchExact: 0,
            videoDescription: false,
            transcripts: false,
            videoTitle: false,

        };


    }

    fetchData() {
        let url = "https://search.sangamtalks.org/backend/search/video";
        if (this.state.searchTerm) {
            url = url + "?search=" + this.state.searchTerm + '&count=12&page=' + this.state.page + '&match_exact=' + this.state.matchExact;
            let search_by = [];
            if (this.state.videoDescription) {
                search_by.push('videoDescription')
            }
            if (this.state.transcripts) {
                search_by.push('transcripts')
            }
            if (this.state.videoTitle) {
                search_by.push("videoTitle")
            }

            if (search_by.length) {
                url = url + "&search_by=" + search_by.join()
            }
        }
        fetch(url, {
            method: 'GET',
        })
            .then(res => res.json())
            .then(
                (result) => {
                    this.setState({
                        isLoaded: true,
                        items: (this.state.page) > 0 ? [...this.state.items, ...result.videos] : result.videos,
                        statistics: result.statistics
                    });
                },
                // Note: it's important to handle errors here
                // instead of a catch() block so that we don't swallow
                // exceptions from actual bugs in components.
                (error) => {
                    console.log(error)
                    this.setState({
                        isLoaded: true,
                        error
                    });
                }
            )
    }

    componentDidMount() {
        this.fetchData();
    }

    render() {
        const { error, isLoaded, items } = this.state;

        const handleClickOpen = () => {
            this.setState({ open: true });
        };
        const handleClose = () => {
            this.setState({ open: false });
        };
        const inputChange = (event) => {
            this.setState({ searchTerm: encodeURIComponent(event.target.value) });
        };
        const onKeyDownEvent = (event) => {
            if (event.key === 'Enter') {
                event.preventDefault();
                this.setState({ page: 0 }, function () {
                    this.fetchData();
                });
            }
        };
        const searchHandler = () => {
            this.setState({ page: 0 }, function () {
                this.fetchData();
            });
        };
        const loadMoreHandler = () => {
            this.setState({ page: this.state.page + 1 }, function () {
                this.fetchData();
            });
        }
        const handleOptionChange = (event) => {
            this.setState({
                matchExact: event.target.value,
                page: 0
            }, function () {
                this.fetchData();
            });
        }

        const handleSearchByChange = (event) => {
            this.setState({
                [event.target.name]: event.target.checked,
                page: 0
            }, function () {
                this.fetchData();
            });
        }

        if (error) {
            return <div>Error: {error.message}</div>;
        } else if (!isLoaded) {
            return <div>
                <Box sx={{ display: 'flex' }}>
                    <CircularProgress />
                </Box>
            </div>;
        } else {
            return (
                <div className={`inner-header ${this.props.className}`}>
                    <form className='search-bar'>
                        <div className='search-wrapper'>
                            <TextField className='search-input'
                                type="text"
                                label="Search"
                                variant="outlined"
                                onChange={inputChange}
                                       onKeyDown={onKeyDownEvent}
                                sx={{
                                    label: {
                                        fontSize: {
                                            xs: '1.2rem',
                                            lg: '1rem'
                                        }
                                    },
                                    input: {
                                        fontSize: {
                                            xs: '1.2rem',
                                            lg: '1rem'
                                        }
                                    }
                                }}
                            />
                            <Button 
                                onClick={searchHandler}
                                variant="contained"
                                color="primary"
                                className='search-button'
                                sx={{
                                    borderRadius: '8px',
                                    backgroundColor: '#00694b',
                                    textTransform: 'unset',
                                    fontSize: {
                                        xs: '1rem',
                                        sm: '1.2rem'
                                    }
                                }}>Search</Button>
                        </div>
                        <div className='options-wrapper'>
                            <FormControl>
                                <RadioGroup
                                    row
                                    defaultValue="0"
                                    name="radio-buttons-group"
                                    className='search-form-labelGroup'
                                >
                                    <FormControlLabel value="0" onChange={handleOptionChange} control={<Radio />} label="Partial Match" />
                                    <FormControlLabel value="1" onChange={handleOptionChange} control={<Radio />} label="Exact Match" />
                                </RadioGroup>
                            </FormControl>
                            <FormGroup row className='search-form-labelGroup'>
                                <FormLabel className='search-form-label'>Search in:</FormLabel>
                                <FormControlLabel className='search-form-label' control={<Checkbox checked={this.state.videoDescription} onChange={handleSearchByChange} name="videoDescription" />} label="Video Description" />
                                <FormControlLabel className='search-form-label' control={<Checkbox checked={this.state.transcripts} onChange={handleSearchByChange} name="transcripts" />} label="Transcript" />
                                <FormControlLabel className='search-form-label' control={<Checkbox checked={this.state.videoTitle} onChange={handleSearchByChange} name="videoTitle" />} label="Title" />
                            </FormGroup>
                        </div>
                    </form>
                    <div className="wrapper-container">
                        <Box sx={{ flexGrow: 1 }}>
                            <Grid container spacing={3}>
                                {items.map((card, index) => (
                                    <Grid item xs={12} sm={6} md={4} lg={4} key={index} sx={{
                                        margin: {
                                            xs: '10px',
                                            sm: 'unset',
                                        }
                                    }}>
                                        <a className={"card-link"} href={card.videoYoutubeUrl} target="blank">
                                        <Box>
                                            <Card>
                                                <CardMedia
                                                    component="img"
                                                    image={card.videoThumbnails.high.url}
                                                    alt={parse(card.videoTitle)}
                                                />
                                                <CardContent>
                                                    <Typography gutterBottom component="div">
                                                    {parse(card.videoTitle)}
                                                    </Typography>
                                                    <Typography gutterBottom variant="caption" component="div">
                                                    {parse(card.channelTitle)}
                                                    </Typography>
                                                    <Typography color="text.secondary">
                                                    {card.videoDescription}
                                                    </Typography>
                                                    {
                                                        card?.transcripts?.en?.transcript?.length &&
                                                            <Typography color="text.secondary" component="div">
                                                                <Typography component="p" variant="caption">Quick Links: </Typography>
                                                                {
                                                                    // This is fixed for 2 links, hence no loop and hardcode indexes.
                                                                    card?.transcripts?.en?.transcript[0] &&
                                                                    <Typography variant="caption" component="p">
                                                                    <a className={"card-link"} href={`${card.videoYoutubeUrl}&t=${card?.transcripts?.en?.transcript[0]?.startpoint}s`} target="blank">
                                                                    {`${parse(card?.transcripts?.en?.transcript[0].text)} (${card?.transcripts?.en?.transcript[0]?.start})`}
                                                                    </a>
                                                                    </Typography>
                                                                }
                                                                {
                                                                    card?.transcripts?.en?.transcript[1] &&
                                                                    <Typography variant="caption" component="p">
                                                                    <a className={"card-link"} href={`${card.videoYoutubeUrl}&t=${card?.transcripts?.en?.transcript[1]?.startpoint}s`} target="blank">
                                                                    {`${parse(card?.transcripts?.en?.transcript[1].text)} (${card?.transcripts?.en?.transcript[1]?.start})`}
                                                                    </a>
                                                                    </Typography>
                                                                }
                                                        </Typography>
                                                    }
                                                </CardContent>
                                            </Card>
                                        </Box>
                                        </a>
                                    </Grid>
                                ))}
                            </Grid>
                        </Box>

                        {this.state.statistics && this.state.statistics.fullCount > 12 && this.state.items?.length >= 12 && this.state.items?.length !== this.state.statistics.fullCount && (
                            <div className="loadMore-button">
                                <Button onClick={loadMoreHandler} variant="contained" color="primary" >Load more</Button>
                            </div>
                        )}

                    </div>
                </div>
            );
        }
    }
}
export default Search;
