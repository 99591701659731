import React from "react";
import Search from "../Search/Search";
import "./search_embed.css";


const Embed = () => {
    return (
       <Search className='embed-search'/>
    );
};

export default Embed;
