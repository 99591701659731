import React from "react";
import logo from "../../src/logo.png";
import "../styles/header.css";

const Header = () => {
  return (
    <div className="logo">
      <img src={logo} width="80" height="80" />
    </div>
  );
};

export default Header;
