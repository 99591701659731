import React from "react";
import {BrowserRouter as Router, Route, Routes} from "react-router-dom";
import App from "./App.js";
import Search from "./Search/Search";
import Footer from "./Footer"
import Header from "./Header";
import Embed from "./Embed/Embed";

const Routing = () => {
    return (
        <Router>
            <Header></Header>
            <Routes>
                <Route path="/" element={<App/>}/>
                <Route path="/search" element={<Search/>}/>
                <Route path="/embed" element={<Embed/>}/>
            </Routes>
            <Footer></Footer>
        </Router>
    );
};
export default Routing;