import React from "react";
import "../styles/footer.css";

const Footer = () => {
  return (
    <div className="Footer-wrapper">
      <div className="Footer">
        Copyright 2023 ©All rights reserved.
      </div>
    </div>
  );
};

export default Footer;
